<template>
	<div
		class="half_layer_wrap"
	>
		<div class="half_layer_wrap_inner">
			<article class="half_view">
				<div class="layer_title">
					<div class="tit_tab">
						<h3 class="focus">{{  $language.common.wrote }}</h3>
					</div>
				</div>
				<div
					class="layer_table" style="max-height: 240px"
					ref="post_list_item_view"
				>
					<table class="nft_info_table">
						<colgroup>
							<col style="width: 75%">
							<col style="width: 25%">
						</colgroup>
						<tbody>
						<template
							v-if="items.length > 0"
						>
							<tr
								v-for="(item, index) in items"
								:key="'item_' + index"
							>
								<td @click="toDetail(item)">{{ item.contents | maxLength(10, '...') }}</td>
							</tr>
						</template>
						<tr
							v-else
						>
							<td colspan="3" class="td_list_none">
								<div class="list_none">
									<img :src="require('@/assets/image/list_none.png')" alt="Not Found History">
									<span>{{  $language.common.NotFoundArticle }}</span>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</article>
			<button class="btn_close" @click="$emit('cancel')">
				<i class="icon icon-close"></i>
			</button>
		</div>
		<div class="bg_overlay"></div>
	</div>
</template>

<script>

export default {
	name: 'mafia0982'
	,
	components: {},
	props: ['user', 'member', 'cartel']
	, data: function(){
		return {
			program: {
				name: 'STAKING ' + this.$language.common.history
			}
			, items: []
			, item_search: this.$storage.init({
				page_number: this.$language.base.page_number
				, pagerecnum: this.$language.base.pagerecnum
			})
			, max: false
			, is_add: false
			, body: ''
		}
	}
	, computed: {

		inquiry_member_number: function(){
			let t = ''

			if(this.member.member_number){
				t = this.member.member_number
			}
			if(this.member.post_member_number){
				t = this.member.post_member_number
			}
			if(this.member.comment_member_number){
				t = this.member.comment_member_number
			}

			return t
		}
	}
	,methods: {
		getData: async function(page = 1){
			try{
				this.$bus.$emit('on', true)
				this.$set(this.item_search, 'page_number', page)
				this.is_add = true
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_comm_cartel_board_list
					, data: {
						member_number: this.user.member_number
						, inquiry_member_number: this.inquiry_member_number
						, cartl_number: this.cartel.cartl_number
						, srchtext: ''
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})
				if(result.success){

					if(result.data.post_list.length > 0){
						this.items = this.items.concat(result.data.post_list)
						this.max = false
					}else{
						this.max = true
					}
				}else{
					this.$bus.$emit('notify',  { type: 'error', messages: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toDetail: function(item){
			this.$bus.$emit('to', { name: 'mafia058', params: {idx: this.$route.params.idx, from: this.$route.name, code: item.board_type_code, b_id: item.board_number, bbs_id: item.bulletin_number}})
		}
		, scrollListen: async function () {

			if(!this.is_add){
				return false
			}

			let w = this.body.scrollBody
			let b = this.body.scrollHeight
			let t = this.body.scrollTop

			if(this.max){
				return false
			}

			let max = b - t
console.log(w, b, t, max)
			if (max <= 280 ) {
				// console.log(`w: ${w}, b: ${b}, m: ${max}, t: ${t}, type: ${this.type}, this.page : ${ this.item_search.page_number }`)

				await this.getData(this.item_search.page_number + 1)
			}
		}
		, removeScroll: function () {
			// console.log('removeScroll')
			this.body.removeEventListener('scroll', this.scrollListen)
		}
		, init: function(){
			this.body = this.$refs.post_list_item_view
			this.body.addEventListener('scroll', this.scrollListen);
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		console.log('member', this.member)
		this.getData()

		setTimeout( () =>{
			this.init()
		}, 100)
	}
}
</script>