<template>
	<div class="half_layer_wrap">
		<div class="half_layer_wrap_inner">
			<article class="half_view">
				<div class="layer_title">
					<div class="tit_tab">
						<h3 class="focus">{{  $language.common.registered_cartel }}</h3>
					</div>
				</div>
				<div class="layer_table overflow-y-auto" style="max-height: 250px;">
					<table class="nft_info_table">
						<colgroup>
							<col style="width: 75%">
							<col style="width: 25%">
						</colgroup>
						<tbody>
						<template
							v-if="items.length > 0"
						>
							<tr
								v-for="(item, index) in items"
								:key="'item_' + index"
							>
								<td @click="$bus.$emit('to', { name: 'mafia049', params: {idx: item.cartl_number, from: $route.name}})">{{ item.cartl_name }}</td>
							</tr>
						</template>
						<tr
							v-else
						>
							<td colspan="3" class="td_list_none">
								<div class="list_none">
									<img :src="require('@/assets/image/list_none.png')" alt="Not Found History">
									<span>{{  $language.cartel.Not_Found_Cartel_Joined }}</span>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</article>
			<button class="btn_close" @click="$emit('cancel')">
				<i class="icon icon-close"></i>
			</button>
		</div>
		<div class="bg_overlay"></div>
	</div>
</template>

<script>
export default {
	name: 'mafia0981'
	, props: ['user', 'member']
	, data: function(){
		return {
			program: {
				name: 'STAKING ' + this.$language.common.history
			}
			, items: []
			, item_search: this.$storage.init({
				page_number: this.$language.base.page_number
				, pagerecnum: 20
			})
		}
	}
	, computed: {

		inquiry_member_number: function(){
			let t = ''

			if(this.member.member_number){
				t = this.member.member_number
			}
			if(this.member.post_member_number){
				t = this.member.post_member_number
			}
			if(this.member.comment_member_number){
				t = this.member.comment_member_number
			}

			return t
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_member_cartel_list
					, data: {
						member_number: this.user.member_number
						, inquiry_member_number: this.inquiry_member_number
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})
				if(result.success){
					this.items = result.data.cartl_search_result
				}else{
					this.$bus.$emit('notify',  { type: 'error', messages: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		console.log('member', this.member)
		this.getData()
	}
}
</script>