<template>
	<div>
		<div class="header" style="z-index: 9999!important; background-color: var(--mafia-Blue)">
			<div class="top top_mafi f-left">
				<div class="util">
					<button
						@click="$emit('cancel')"
						class="arrow_white"
					>
						<i class="icon icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit">{{ item_friend.nickname | maxLength(12, '...') }}</h2>
			</div>
		</div>
		<div class="section_wrap pt-50">
			<!-- 멤버상세설명 -->
			<div class="container">
				<div class="subindex_mainbox">
					<div class="myphoto">
						<img
							v-if="item_friend.profle_nft_card_img"
							:src="$request.upload_url(item_friend.profle_nft_card_img)" alt="nft card"
						>
						<img
							v-else
							:src="require('@/assets/image/@noimage.png')" :alt="$language.cartel.recommend_cartel"
						/>
					</div>
					<div class="myintroduce">
						<p class="name">{{ item_friend.nickname }}</p>
						<p class="desc">{{ item_friend.state_message }}</p>
					</div>
					<div class="interest_wrap">
						<span
							v-for="(fav, index) in item_friend.concern_sphere_list"
							:key="'fav_' + index"
							class="inter_40 " :class="'inter_40_' + fav.concern_sphere_name.toLowerCase()"
						><em class="hide">{{ fav.concern_sphere_name }}</em></span>
					</div>
					<div
						v-if="is_stat"
					>
						<div
							v-if="item_friend.member_number"
							class="ct_action_data"
						>
							<p class="info"><span class="tit">{{  $language.common.visit }}</span><em>{{  item_friend.visitor_count }}</em></p>
							<p class="info"><span class="tit">{{  $language.notice.register_article }}</span><em>{{ item_friend.writing_articles_count }}</em></p>
						</div>
					</div>
				</div>
			</div>
			<!-- //멤버상세설명 -->
			<!-- 친구추가-->
			<div
				v-if="item_friend.member_number"
				class="bottom_btn"
			>
				<div class="container">
					<div class="row">
						<template
							v-if="item_friend.self_fg != 'Y'"
						>
						<div
							v-if="is_friend"
							class="btn_area pb-20"
							@click="onConfirm(false)"
						>
							<button class="btn_l btn_fill_red">{{  $language.confirm.cancel_friend.title }}</button>
						</div>
						<div
							v-else
							@click="onConfirm(true)"
							class="btn_area pb-20"
						>
							<button class="btn_l btn_fill_blue">{{  $language.confirm.add_friend.title }}</button>
						</div>
						</template>
						<div class="target_link pb-30">
							<a
								v-if="is_stat"
								@click="is_0982 = true"
							>{{  $language.common.check_wrote }} <!-- 작성글 확인 --></a>
							<a
								@click="is_0981 = true"
							>{{  $language.common.registered_cartel }} <!-- 가입 카르텔 --></a>
						</div>
					</div>
				</div>
			</div>
			<!-- //친구추가-->
		</div>
		<mafia0981
			v-if="is_0981"
			:user="user"
			:member="item_user"
			:cartel="cartel"

			@cancel="is_0981 = false"
		></mafia0981>
		<mafia0982
			v-if="is_0982"
			:user="user"
			:member="item_user"
			:cartel="cartel"

			@cancel="is_0982 = false"
		></mafia0982>

		<popup_confirm
			v-if="is_confirm"

			@click="postFriend"
			@cancel="is_confirm = false"
		>
			<template
				v-slot:title
			>{{ title_text }}</template>
			<template
				v-slot:main-txt
			>{{ title_text }}</template>
			<template
				v-slot:sub-txt
			>{{ title_text }}<br/>
				<div
					v-if="!is_friend"
					class="bright_checks md_check">
					<input v-model="is_notify" type="checkbox" id="mdChk">
					<label for="mdChk">{{  $language.confirm.add_friend.sub2 }}</label>
				</div>
			</template>
		</popup_confirm>

	</div>
</template>

<script>
	import mafia0981 from "@/view/Cartel/mafia098-1"
	import mafia0982 from "@/view/Cartel/mafia098-2"
	import popup_confirm from "@/view/Layout/PopupConfirm";
	export default {
		name: 'mafia098'
		, components: {popup_confirm, mafia0981, mafia0982}
		, props: ['cartel', 'user', 'friend']
		, data: function(){
			return {
				program: {
					name: this.$language.common.searchInfoUser
					, title: this.$language.common.searchInfoUser
					, not_footer: true
					, not_header: true
					, type: 'cartel_sub'
				}
				, is_0981: false
				, is_0982: false
				, item_user: this.friend
				, confirm_type: ''
				, is_confirm: false
				, item_friend: { }
				, is_notify: true
			}
		}
		, computed: {
			is_friend: function(){
				let t = false
				if(this.item_friend.member_friend_fg == 'Y'){
					t = true
				}
				return t
			}
			, is_stat: function(){
				let t = false
				if(this.cartel.cartl_number){
					t = true
				}
				return t
			}
			, title_text: function(){
				let t = ''
				if(this.confirm_type){
					t = this.$language.common.do_you_add_friend
				}else{
					t = this.$language.common.do_you_cancel_friend
				}
				return t
			}
			, inquiry_member_number: function(){
				let t = ''

				if(this.friend.member_number){
					t = this.friend.member_number
				}
				if(this.friend.post_member_number){
					t = this.friend.post_member_number
				}
				if(this.friend.comment_member_number){
					t = this.friend.comment_member_number
				}

				return t
			}
		}
		, methods: {
			onConfirm: function(type){
				this.confirm_type = type
				this.is_confirm = true
			}
			, postFriend: async function(){
				let url = this.$api_url.api_path.post_friend_add
				if(this.is_friend){
					url = this.$api_url.api_path.post_friend_cancel
				}
				try {
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, friend_member_number: this.item_friend.member_number
							, note_fg: this.is_notify ? 'Y' : 'N'
						}
						, type: true
					})

					console.log('postFriend result ', result)
					if(result.success){
						this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.is_confirm = false
					this.$bus.$emit('on', false)
					await this.getMyFriend()
				}
			}
			, getMyFriend: async function(){
				try {
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_member_info
						, data: {
							member_number: this.user.member_number || this.user.post_member_number
							, inquiry_member_number: this.inquiry_member_number
							, cartl_number: this.cartel.cartl_number
						}
						, type: true
					})

					if(result.success){
						this.item_friend = result.data.MmbBasicInfo
						this.item_friend.concern_sphere_list = result.data.concern_sphere_list
					}else{
						this.item_friend = {
							nickname: result.message
						}
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			// this.$bus.$emit('onLoad', this.program)
			if(this.$route.params.idx){
				this.cartel.cartl_number = this.$route.params.idx
			}
			this.getMyFriend()
		}
	}
</script>

<style>
.subindex_mainbox .myintroduce .name {
	overflow: unset !important;
	text-overflow: unset !important;
	word-break: break-all;
	white-space: normal !important;
}
</style>